// colors
$aquamarine: #463fcd; // purple
$aquamarinedark: #b433ff; // testimonials background
$pumpkin:  #f74813; // letters
$pumpkinlight: #a31d18; // letters
$lightercolor: #f6e8fe;
$lightercolor1: #fbf5fe;

$color001: #581c87;
$color002: #6b21ab;
$color003: #894dbc;

$yellowgrayish: #f3e184;
$grayishdark: #3A3A3A;
$graylight: rgb(206, 206, 206);

// laptop
@mixin font-title{
    font-size: 1.6em;
}

@mixin font-text{
    font-size: 1.3em;
}

// mobile
@mixin font-mobile-title{
    font-size: 1.25em;
}

@mixin font-mobile-text{
    font-size: 1.18em;
}

// footer
@mixin font-text-small{
    font-size: .8em;
}
@mixin font-text-xsmall{
    font-size: .7em;
}



